import React from 'react';
import PropTypes from 'prop-types';
import './navigation.scss';

const Navigation = ({ step, maxStep }) => {
  const numbers = [];
  for (let i = 0; i < maxStep; i++) numbers.push(i);
  return (
    <div className="navigation">
      {numbers.map(slide => (
        <div key={slide} className={`${slide === step ? 'active' : ''}`} />
      ))}
    </div>
  );
};

Navigation.propTypes = {
  step: PropTypes.number,
  maxStep: PropTypes.number,
};

export default Navigation;
