import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert as RAlert } from 'reactstrap';
import styles from './alert.module.scss';

const Alert = ({ msg, color, position }) => {
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);

  return (
    <div
      className={styles.appAlertContainer}
      style={position ? { top: 100 } : { bottom: 0 }}
    >
      <RAlert color={color} isOpen={visible} toggle={onDismiss}>
        {msg}
      </RAlert>
    </div>
  );
};

Alert.propTypes = {
  color: PropTypes.oneOf(['danger', 'success']),
  msg: PropTypes.string,
};

export default Alert;
