import React, { useState } from 'react';
import _ from 'lodash';
import Button from 'components/Button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './submit.scss';

export default ({
  submitTitle,
  submitContent,
  isValid,
  submit,
  showSubmitModal,
  closeSubmitModal,
  isSubmitting,
  touched,
  buttonName,
}) => {
  const [modal, toggleModal] = useState(false);
  return (
    <div className="saveModal">
      <Button
        type={isValid ? 'app-primary-button' : ' app-primary-button inactive'}
        handleClick={() => {
          if (isValid) {
            submit();
            toggleModal(!modal);
          }
        }}
        disabled={!!!Object.keys(touched).length || !isValid || isSubmitting}
        btnType="submit"
      >
        {buttonName || 'Submit'}
      </Button>
      {showSubmitModal && (
        <Modal
          isOpen={modal}
          toggle={() => {
            toggleModal(!modal);
            modal && _.isFunction(closeSubmitModal) && closeSubmitModal();
          }}
          backdrop={true}
          centered={true}
          className="save-modal"
        >
          <div className="modal-content">
            <ModalHeader toggle={closeSubmitModal}></ModalHeader>
            <ModalBody>
              <div>
                <h3 className="modal-title">{submitTitle}</h3>
              </div>
              <div className="modal-content user-input-form">
                <p>{submitContent}</p>
              </div>
            </ModalBody>
          </div>
        </Modal>
      )}
    </div>
  );
};
