import React from 'react';

export const BackArrow = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.18546 0.240629C7.48517 -0.0802095 7.98399 -0.0802095 8.29419 0.240629C8.5939 0.550628 8.5939 1.06657 8.29419 1.37585L2.67228 7.19068H15.2238C15.6563 7.1914 16 7.54692 16 7.99422C16 8.44151 15.6563 8.8086 15.2238 8.8086H2.67228L8.29419 14.6126C8.5939 14.9334 8.5939 15.4501 8.29419 15.7594C7.98399 16.0802 7.48447 16.0802 7.18546 15.7594L0.232645 8.56797C-0.077549 8.25797 -0.077549 7.74203 0.232645 7.43275L7.18546 0.240629Z"
      fill="#242424"
    />
  </svg>
);
