import React, { useState } from 'react';
import { Field } from 'formik';
import { isEmpty } from 'lodash';

const Textarea = ({
  name,
  placeholder,
  showErrorMessages = true,
  handleClick,
}) => {
  const [touched, setTouched] = useState(false);
  return (
    <Field name={name}>
      {({ field, meta, setFieldTouched }) => (
        <div className="app-input-text">
          <textarea
            className={`${
              meta.touched && meta.error
                ? 'error'
                : isEmpty(placeholder) && meta.touched
                ? ''
                : 'placeholder'
            }`}
            name={field.name}
            placeholder={placeholder}
            value={field.value}
            onClick={e => {
              isEmpty(placeholder) && handleClick('message', true);
            }}
            {...field}
          />

          {meta.touched && meta.error && showErrorMessages && (
            <p className="error-message">{meta.error}</p>
          )}
        </div>
      )}
    </Field>
  );
};

export default Textarea;
