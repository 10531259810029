import * as Yup from 'yup';

export function getConsignmentInitialValues() {
  return {
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: 'United States',
    zipcode: '',
    phone: null,
    email: '',
    year: '',
    make: '',
    model: '',
    images: [],
    engine: '',
    chassis: '',
    body: '',
    interior: '',
    interiorColor: '',
    exterior: '',
    exteriorColor: '',
    referral: '',
  };
}

const step1Schema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required.'),
  lastName: Yup.string().required('Last Name is required.'),
  address1: Yup.string().required('Address 1 is required.'),
  city: Yup.string().required('City is required.'),
  state: Yup.string().required('State is required.'),
  country: Yup.string().required('Country is required.'),
  zipcode: Yup.string().required('Zipcode is required.'),
  phone: Yup.string().required('Phone is required').nullable(),
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Email is required.'),
});

const step2Schema = Yup.object().shape({
  year: Yup.string().required('Year field is required.'),
  make: Yup.string().required('Make field is required.'),
  model: Yup.string().required('Model field is required.'),
});
export function getConsignmentSchema() {
  return [step1Schema, step2Schema];
}
